import React from 'react';
import styled from 'styled-components';
import { FaInstagram, FaWhatsapp, FaFacebook } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background: linear-gradient(135deg, #5f587a, #5f587a);
  color: white;
  text-align: center;
  padding: 20px 0;
`;

const Title = styled.h2`
  margin: 0;
  padding: 10px 0;
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px 0;
`;

const IconLink = styled.a`
  color: white;
  font-size: 24px;
  transition: color 0.3s;

  &:hover {
    color: #ccc;
  }
`;

const FooterText = styled.p`
  margin: 0;
  padding: 10px 0;
`;

function Footer() {
  return (
    <FooterContainer>
      <Title>Redes Sociales y Contacto</Title>
      <IconsContainer>
        <IconLink href="https://www.instagram.com/pametajonarmendez?igsh=MW5hNGd4MDQ4amNoYQ==" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </IconLink>
        <IconLink href="https://wa.me/522222057203" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp />
        </IconLink>
        <IconLink href="https://www.facebook.com/pame.tajonar?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </IconLink>
      </IconsContainer>
      <FooterText>&copy; Todos los derechos reservados</FooterText>
    </FooterContainer>
  );
}

export default Footer;
